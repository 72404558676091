<template>
  <Html :lang="locale">
    <Head>
      <slot></slot>
      <Title>{{ preparedTitle }}</Title>
      <Meta name="description" :content="preparedDescription" />
      <Meta name="keywords" :content="preparedKeyWords" />
      <Meta name="theme-color" content="#ffffff" />
      <Meta name="generator" :content="`Nuxt ${versionNuxt}`" />
      <!-- og meta tags -->
      <Meta property="og:title" :content="preparedTitle" />
      <Meta property="og:type" :content="og?.type ?? 'website'" />
      <Meta
        property="og:image"
        :content="og?.image ?? `image/og-image-${brand}.png`"
      />
      <Meta property="og:url" :content="`${siteUrl}${route.path}`" />
      <Meta property="og:description" :content="preparedDescription" />
      <Meta property="og:site_name" :content="brandWithCapitalizeFirstLetter" />
      <!-- favicon -->
      <Link
        rel="apple-touch-icon"
        :href="`favicon/${brand}/apple-touch-icon.png`"
      />
      <Link rel="icon" :href="`/favicon/${brand}/favicon.ico`" sizes="any" />
      <Link rel="manifest" :href="`/favicon/${brand}/manifest.webmanifest`" />
      <slot name="styles"></slot>
      <slot name="scripts"></slot>
    </Head>
  </Html>
</template>

<script setup lang="ts">
interface ogMetaTags {
  title?: string;
  type?:
    | "website"
    | "article"
    | "book"
    | "profile"
    | "music.song"
    | "music.album"
    | "music.playlist"
    | "music.radio_station"
    | "video.movie"
    | "video.episode"
    | "video.tv_show"
    | "video.other";
  image?: string;
  url?: string;
  description?: string;
  siteName?: string;
}

interface IPageSeoData {
  title?: string;
  description?: string;
  keyWords?: string;
  og?: ogMetaTags;
}

const props = defineProps<IPageSeoData>();

const {
  public: { brand, brandWithCapitalizeFirstLetter, siteUrl },
} = useRuntimeConfig();
const {
  versions: { nuxt: versionNuxt },
} = useNuxtApp();
const { locale, t } = useI18n();
const route = useRoute();

const preparedTitle = computed(
  () =>
    props.title ??
    t("page_seo_data.index.title", { brand: brandWithCapitalizeFirstLetter })
);

const preparedDescription = computed(
  () =>
    props.description ??
    t("page_seo_data.index.description", {
      brand: brandWithCapitalizeFirstLetter,
    })
);

const preparedKeyWords = computed(
  () =>
    props.keyWords ??
    t("page_seo_data.index.keyWords")
);
</script>
